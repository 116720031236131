import React from "react"
import { StaticQuery, graphql } from "gatsby"
import Nav from "./nav";

export default (props) => (
   <StaticQuery
      query={graphql`
      query GetCollectionNames {
         allCollectionsJson {
            edges {
               node {
                  name
               }
            }
         }
      }
    `}
      render={data => (
            <Nav collections={getCollectionNames(data.allCollectionsJson)}
                 galleryLinksOpen={props.galleryLinksOpen}/>
      )}
   />
)

const getCollectionNames = (allCollectionsJson) => {
   return allCollectionsJson.edges.map(edge => edge.node.name);
};


