import React from "react"
import {Link} from "gatsby"
import GalleryLinks from "./gallery-links";
import Collapse from '@material-ui/core/Collapse';
require('typeface-raleway');
require('typeface-frank-ruhl-libre');


const ListLink = props => (
   <li style={{display: `inline-block`, marginRight: `2rem`, marginBottom: `.5rem`}}>
      <Link to={props.to} className={`nav-text frank-text`} style={{fontSize: `1.2em`}}>{props.children}</Link>
   </li>
);

export default class Nav extends React.Component {
   constructor(props) {
      super(props);

      this.state = {
         galleryLinksOpen: this.props.galleryLinksOpen
      };
   }

   onGalleriesClicked() {
      this.setState({galleryLinksOpen: !this.state.galleryLinksOpen});
   }

   render() {
      return (
         <div style={{display: 'block'}} className={'nav-wrapper'}>
            <header className={`header`} style={{display: 'flex', flexDirection: 'column'}}>
               <Link to="/" style={{textShadow: `none`, backgroundImage: `none`}}>
                  <h3 style={{display: `inline`, fontSize: `2.9em`}} className={`nav-text website-title-nav-text`}>Ross Crane</h3>
               </Link>
               <ul style={{listStyle: `none`, float: `right`, display: 'flex', flexDirection: 'column', paddingLeft: '5px'}}>

                  <li style={{display: `inline-block`, marginRight: `2rem`, marginBottom: `.5rem`}}
                      onClick={this.onGalleriesClicked.bind(this)}>
                     <p style={{fontSize: `1.2em`, margin: '0'}} className={`nav-text frank-text`}>Gallery</p>
                  </li>

                  <Collapse in={this.state.galleryLinksOpen} collapsedHeight="0px">
                     <GalleryLinks collections={this.props.collections}/>
                  </Collapse>
                  
                  <ListLink to="/contact">Contact</ListLink>
                  <ListLink to="/about">About</ListLink>
               </ul>
            </header>
         </div>
      )
   }
}


