import React from "react"
import {Link} from "gatsby";

const CollectionLink = props => (
   <li className={"nav-text"} style={{display: `inline-block`, marginLeft: `1rem`, marginBottom: `.5rem`}}>
      <Link to={`/gallery/${props.name}`} className={`nav-text frank-text`} style={{fontSize: `1.2em`}}>{capitalizeFirstLetter(props.name)}</Link>
   </li>
);


function capitalizeFirstLetter(string) {
   return string.charAt(0).toUpperCase() + string.slice(1);
}

export default class GalleryLinks extends React.Component {
   render() {
      return (
         <div style={{display: 'flex', flexDirection: 'column'}}>
            {
               this.props.collections.map(collection => <CollectionLink name={collection} key={collection}/>)
            }
         </div>
      )
   }
}


