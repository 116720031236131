import React, {Component} from "react"
import "typeface-roboto"
import "../styles/global.css"
import {Location} from '@reach/router';
import NavWrapper from "./nav-wrapper";
import withWidth, { isWidthUp } from '@material-ui/core/withWidth';

class Layout extends Component {
   getSiteStyle() {
      const isNotMobile = !this.isMobile();
      const flexDirection = isNotMobile ? 'row' : 'column';
      const sidePadding  = isNotMobile ? '2' : '.5';

      return {margin: `0 auto`, padding: `0 ${sidePadding}rem`, display: 'flex', flexDirection, maxHeight: "90vh"};
   }

   getPadding() {
      return this.isMobile() ? "0px" : "50px";
   }

   isMobile() {
      return !isWidthUp('xl', this.props.width) && !isWidthUp('lg', this.props.width) && !isWidthUp('md', this.props.width);
   }


   render() {
      return (
         <Location>
            {({location}) => {
               return location.pathname === '/admin'
                  ? this.props.children
                  : <div style={this.getSiteStyle()}>
                     <NavWrapper galleryLinksOpen={this.props.galleryLinksOpen}/>

                     <div style={{ flex: 1, paddingLeft: this.getPadding(), paddingTop: this.getPadding(), paddingBottom: "1.5rem"}}>
                        {this.props.children}
                     </div>
                  </div>
            }}
         </Location>
      );
   }
}

export default withWidth()(Layout);